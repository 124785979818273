import React from "react";
import { useState, useEffect } from "react";
import axios from "../Api/axios";
import Swal from "sweetalert2";

function FgPassword() {
  const [fgEmail, setFgEmail] = useState("");
  const [show, setShow] = useState(true);

  async function submitHandler() {
    const data = {
      email: fgEmail,
    };
    console.log(data);
    try {
      await axios.post("api/v1/users/forgot-password", data).then((res) => {
        console.log(res);
        setShow(false);
        // Swal.fire({
        //   text: "The Password Reset Link Has Been Sent To Your Email",
        //   icon: "success",
        // });
      });
    } catch (error) {
      Swal.fire({
        title: "Error!",
        text: error,
        icon: "error",
        confirmButtonText: "Close",
      });
    }
  }

  return (
    <div className="d-flex justify-content-center align-items-center container flex-column">
      {show ? (
        <div className="d-flex justify-content-center align-items-center flex-column">
          <h3 className="mb-3 text-center text-light">
            Enter Your Email To Reset Your Password
          </h3>
          <div className="form-group inputs">
            <label htmlFor="email" className="form-label">
              Email Address
            </label>
            <input
              type="type"
              id="email"
              value={fgEmail}
              className="form-control"
              autoComplete="off"
              required
              onChange={(e) => setFgEmail(e.target.value)}
            />
            <div className="invalid-feedback">Please Enter Email</div>
          </div>
          <button onClick={submitHandler} className="mt-3 loginBtns mx-auto">
            Submit
          </button>
        </div>
      ) : (
        <h3 className="mb-3 text-center text-light">
          The Password Reset link is successfully send to your mail
        </h3>
      )}
    </div>
  );
}

export default FgPassword;
