import React, { useRef, useState } from "react";
import { styled } from "@mui/material/styles";
import axios from "../../Api/axios";
import CollectionsIcon from "@mui/icons-material/Collections";
import Checkbox from "@mui/material/Checkbox";
import StarBorderIcon from "@mui/icons-material/StarBorder";
import DeleteIcon from "@mui/icons-material/Delete";
import CloudUploadIcon from "@mui/icons-material/CloudUpload";
import { Star } from "@mui/icons-material";
import { IconButton } from "@mui/material";
import Swal from "sweetalert2";

const VisuallyHiddenInput = styled("input")({
  clip: "rect(0 0 0 0)",
  clipPath: "inset(50%)",
  height: 1,
  overflow: "hidden",
  position: "absolute",
  bottom: 0,
  left: 0,
  whiteSpace: "nowrap",
  width: 1,
});

function AddShowcase({ closeModal }) {
  const [images, setImages] = useState([]);
  // const [pjimages, setPjImages] = useState([]);
  const fileInputRef = useRef(null);
  const titleRef = useRef(null);
  const descRef = useRef(null);
  const projectTypeRef = useRef(null);
  const [categery, setCategory] = useState("");
  const [fav, setfav] = useState(false);

  const modalClose = () => {
    closeModal();
  };

  function ImageHandler(event) {
    const files = event.target.files;
    if (files.length === 0) return;
    for (let i = 0; i < files.length; i++) {
      if (files[i].type.split("/")[0] !== "image") continue;
      if (!images.some((e) => e.name === files[i].name)) {
        setImages((prevImages) => [...prevImages, files[i]]);
      }
    }
    console.log(files);
    // setPjImages(event.target.files);
  }

  console.log("images", typeof images);

  // To Delete the image
  function deleteImages(index) {
    setImages((prevImages) => prevImages.filter((_, i) => i !== index));
  }

  async function AddShowcase() {
    const data = new FormData();
    for (let i = 0; i < images.length; i++) {
      data.append("images", images[i]);
    }
    data.append("title", titleRef.current.value);
    data.append("description", descRef.current.value);
    data.append("category", categery);
    data.append("favorite", fav);
    data.append("project_type", projectTypeRef.current.value);

    console.log(data);
    try {
      const res = await axios.post("api/v1/contents/upload", data, {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      });
      // console.log(res);
      modalClose();
    } catch (err) {
      console.log(err.response);
      modalClose();
      Swal.fire({
        title: "Error!",
        text: err.response.data,
        icon: "error",
        confirmButtonText: "Close",
      });
    }
  }

  return (
    <div
      style={{
        height: "80vh",
        overflowX: "hidden",
        padding: "30px",
        paddingTop: "0",
      }}
    >
      <p style={{ fontSize: "24px", fontWeight: "bold" }}>Add New Project</p>
      <div className="mb-3">
        <div className="row">
          <div className="col-6">
            <div className="py-2 bg-dark rounded">
              <div className="px-3">
                <label
                  htmlFor="exampleFormControlInput1"
                  className="form-label text-light"
                >
                  Project Title
                </label>
                <input
                  type="text"
                  className="form-control"
                  id="exampleFormControlInput1"
                  placeholder="Enter Project Name"
                  ref={titleRef}
                />
              </div>
            </div>
          </div>

          <div className="col-6 ">
            <div className="py-2 bg-dark rounded">
              <div className="px-3">
                <label
                  htmlFor="exampleFormControlInput1"
                  className="form-label text-light"
                >
                  Project Type
                </label>
                <input
                  type="text"
                  className="form-control"
                  id="exampleFormControlInput1"
                  placeholder="Enter Project Type"
                  ref={projectTypeRef}
                />
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="mb-3">
        <div className="row py-2">
          <div className="col-6">
            <div className="py-2 bg-dark rounded">
              <div className="px-3">
                <label
                  htmlFor="exampleFormControlInput1"
                  className="form-label text-light"
                >
                  Project Category
                </label>
                <select
                  className="form-select"
                  aria-label="Default select example"
                  onChange={(e) => setCategory(e.target.value)}
                >
                  <option defaultValue={null}>Open this select menu</option>
                  <option value="Construction">Construction</option>
                  <option value="Design">Design</option>
                  <option value="Steel Construction">Steel</option>
                  <option value="Decoration">Decoration</option>
                </select>
              </div>
            </div>
          </div>
          <div className="col-6">
            <div className="py-2 bg-dark rounded">
              <div className="px-3">
                <label
                  htmlFor="exampleFormControlInput1"
                  className="form-label text-light"
                >
                  Set Project Into Favourite
                </label>
                <div className="bg-light rounded d-flex justify-content-center align-items-center">
                  <span
                    className="text-primary mt-1"
                    style={{ fontSize: "18px", fontWeight: "bold" }}
                  >
                    Favourite
                  </span>
                  <Checkbox
                    onChange={(e) => setfav(e.target.checked)}
                    icon={<StarBorderIcon />}
                    checkedIcon={<Star />}
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="col-12 mb-3">
        <div className="py-2 bg-dark rounded">
          <div className="px-3">
            <label
              htmlFor="exampleFormControlInput1"
              className="form-label text-light"
            >
              Project Description
            </label>
            <input
              type="text"
              className="form-control"
              id="exampleFormControlInput1"
              placeholder="Describe about the project in details..."
              ref={descRef}
            />
          </div>
        </div>
      </div>
      <div className="bg-dark rounded py-2">
        <span className="text-white px-3">Project Images</span>
        <div className="d-flex justify-content-center align-items-center flex-column">
          <IconButton
            sx={{
              background: "white",
              borderRadius: "5px",
              padding: "5px 10px",
            }}
            component="label"
            variant="contained"
            tabIndex={-1}
          >
            <VisuallyHiddenInput
              type="file"
              multiple
              ref={fileInputRef}
              onChange={ImageHandler}
            />
            <CollectionsIcon sx={{ fontSize: "25px", color: "#007FFF" }} />
          </IconButton>
          <p className="text-white mt-2">Select Images related to porject</p>
        </div>
      </div>

      <div className="pj-containers">
        {images.map((images, index) => (
          <div className="pj-images" key={index}>
            <span className="pj-delete" onClick={() => deleteImages(index)}>
              &times;
            </span>
            <img src={URL.createObjectURL(images)} alt="pj" />
          </div>
        ))}
      </div>

      <div className="pt-3 float-end">
        <button className="btn btn-outline-danger " onClick={AddShowcase}>
          <DeleteIcon sx={{ color: "red" }} />
          <p style={{ marginBottom: "0", color: "red" }}>Delete</p>
        </button>
        <button className="btn btn-primary ms-2" onClick={AddShowcase}>
          <CloudUploadIcon />
          <p style={{ marginBottom: "0" }}>Upload</p>
        </button>
      </div>
      <button className="close" onClick={modalClose}>
        <span>&#10005;</span>
      </button>
    </div>
  );
}

export default AddShowcase;
