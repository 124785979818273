import axios from "./../../Api/axios";
import React, { useState, useRef } from "react";
// import AuthContext from "../../Contexts/AuthProvider";
import DeleteIcon from "@mui/icons-material/Delete";
import CloudUploadIcon from "@mui/icons-material/CloudUpload";
// import "../../Components/bannerImg.css";
import Swal from "sweetalert2";
import { styled } from "@mui/material/styles";
import { IconButton } from "@mui/material";
import PermMediaIcon from "@mui/icons-material/PermMedia";

const VisuallyHiddenInput = styled("input")({
  clip: "rect(0 0 0 0)",
  clipPath: "inset(50%)",
  height: 1,
  overflow: "hidden",
  position: "absolute",
  bottom: 0,
  left: 0,
  whiteSpace: "nowrap",
  width: 1,
});

function ModalTest({ close }) {
  // const { auth } = useContext(AuthContext);
  const [name, setName] = useState([]);
  const [company, setCompany] = useState([]);
  const [body, setBody] = useState([]);
  const [profile, setProfile] = useState();
  const [pfName, setPfName] = useState([]);
  const fileInputRef = useRef(null);
  // const modalRef = useRef(null);

  function ImageHandler(e) {
    setProfile(e.target.files[0]);
    setPfName(e.target.files[0].name);
  }

  // console.log(profile.name);

  // To select the image
  // function selectFile() {
  //   fileInputRef.current.click();
  // }

  // To Delete the image
  function deleteImages() {
    setName(null);
    setCompany(null);
    setBody(null);
    setProfile(null);
    setPfName(null);
  }

  function closeModal() {
    console.log("close");
    close();
  }

  async function ApiHandler() {
    const data = {
      profile: profile,
      name: name,
      company: company,
      body: body,
    };

    console.log(data);
    try {
      await axios
        .post("api/v1/testimonials/create", data, {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        })
        .then((res) => {
          console.log(res);
          close();
          Swal.fire({
            text: "Uploaded",
            icon: "success",
          });
        });
    } catch (error) {
      console.log(error);
      close();
      Swal.fire({
        title: "Error!",
        text: error.response.data.error,
        icon: "error",
        confirmButtonText: "Close",
      });
    }
  }

  return (
    <div className="px-3">
      <div className="d-flex justify-content-between">
        <h5 className="mb-3">Add New Testimonial</h5>
        <button className="delete text-black" onClick={closeModal}>
          Close
        </button>
      </div>

      <div className="row mb-1">
        <div className="col-6">
          <div className="py-2 bg-dark rounded">
            <div className="px-3">
              <label
                htmlFor="exampleFormControlInput1"
                className="form-label text-light"
              >
                Profile Image
              </label>
              <div className="d-flex">
                <input
                  type="text"
                  className="form-control"
                  id="exampleFormControlInput1"
                  placeholder="Choose Profile Image"
                  value={pfName || "Choose Profile Image"}
                  readOnly
                />
                <IconButton
                  component="label"
                  sx={{
                    background: "white",
                    borderRadius: "10px",
                    color: "#007fff",
                    marginLeft: "10px",
                  }}
                  role={undefined}
                >
                  <PermMediaIcon />
                  <VisuallyHiddenInput
                    type="file"
                    ref={fileInputRef}
                    onChange={ImageHandler}
                  />
                </IconButton>
              </div>
            </div>
          </div>
        </div>

        <div className="col-6">
          <div className="py-2 bg-dark rounded">
            <div className="px-3">
              <label
                htmlFor="exampleFormControlInput1"
                className="form-label text-light"
              >
                Customer Name
              </label>
              <input
                type="text"
                className="form-control"
                id="exampleFormControlInput1"
                placeholder="Enter Project Type"
                onChange={(e) => setName(e.target.value)}
              />
            </div>
          </div>
        </div>
      </div>

      <div className="">
        <div className="row py-2">
          <div className="col-6 ">
            <div className="py-2 bg-dark rounded">
              <div className="px-3">
                <label
                  htmlFor="exampleFormControlInput1"
                  className="form-label text-light"
                >
                  Company
                </label>
                <input
                  type="text"
                  className="form-control"
                  id="exampleFormControlInput1"
                  placeholder="Enter Project Type"
                  onChange={(e) => setCompany(e.target.value)}
                />
              </div>
            </div>
          </div>

          <div className="col-6"></div>
        </div>
      </div>

      <div className="mb-1">
        <div className="row py-2">
          <div className="">
            <div className="py-2 bg-dark rounded">
              <div className="px-3">
                <label
                  htmlFor="exampleFormControlInput1"
                  className="form-label text-light"
                >
                  Customer Testimonial
                </label>
                <textarea
                  className="form-control mt-1"
                  id=""
                  rows="5"
                  onChange={(e) => setBody(e.target.value)}
                ></textarea>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="pt-3 float-end">
        <button className="btn btn-outline-danger" onClick={deleteImages}>
          <DeleteIcon sx={{ color: "red" }} />
          <p style={{ marginBottom: "0", color: "red" }}>Discard</p>
        </button>
        <button className="btn btn-primary ms-2" onClick={ApiHandler}>
          <CloudUploadIcon />
          <p style={{ marginBottom: "0" }}>Upload</p>
        </button>
      </div>
    </div>
  );
}

export default ModalTest;
