import React, { useState } from "react";
import AllShowcase from "./AllShowcase";
import ShowcaseDetail from "./ShowcaseDetail";

import "./showcase.css";

function Showcase() {
  const [page, setpage] = useState(false);
  const [id, setid] = useState();
  function openPage(id) {
    setpage(true);
    setid(id);
  }
  console.log(page);
  return (
    <div>
      <h4 className="contactTitle mb-3">Finished Projects</h4>
      {page ? <ShowcaseDetail id={id} /> : <AllShowcase openPage={openPage} />}
    </div>
  );
}

export default Showcase;
