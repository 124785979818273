import React from "react";
import ReactDOM from "react-dom/client";
import App from "./App";
import { AuthProvider } from "./Contexts/AuthProvider";
import MobileBlocker from "./MobileBlocker";

// Bootstrap CSS
import "bootstrap/dist/css/bootstrap.min.css";
// Bootstrap Bundle JS
import "bootstrap/dist/js/bootstrap.bundle.min";

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  <React.Suspense
    fallback={
      <div style={{ color: "black", marginTop: "300px" }}>Loading...</div>
    }
  >
    <MobileBlocker>
      <App />
    </MobileBlocker>
  </React.Suspense>
);
