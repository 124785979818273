import React from "react";
import "../Components/LoginForm.css";
import { useRef, useState, useEffect } from "react";
import { setAuthToken } from "../Api/axios";
import axios from "../Api/axios";
import { NavLink, useNavigate } from "react-router-dom";
import Swal from "sweetalert2";
import { jwtDecode } from "jwt-decode";
import RemoveRedEyeIcon from "@mui/icons-material/RemoveRedEye";
import VisibilityOffIcon from "@mui/icons-material/VisibilityOff";
import CircularProgress from "@mui/material/CircularProgress";

// const LOGIN_URL = "https://kkk.otastechsolutions.com/api/v1/users/login";

const LoginForm = () => {
  const navigate = useNavigate();
  const userRef = useRef();

  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [visiable, setVisable] = useState("password");

  useEffect(() => {
    userRef.current.focus();
  }, []);

  const submitHandler = async (e) => {
    e.preventDefault();
    Swal.fire({
      title: "Logging In...",
      html: "Please wait while we process your request.",
      allowOutsideClick: false,
      didOpen: () => {
        Swal.showLoading();
      },
    });
    try {
      const res = await axios.post(
        "api/v1/users/login",
        JSON.stringify({ email, password })
      );

      const accessToken = res.data.token;
      setAuthToken(accessToken);
      localStorage.setItem("kk-token", accessToken);
      let decodedToken = jwtDecode(accessToken);
      localStorage.setItem("expirationTime", decodedToken.iat * 1000);
      setEmail("");
      setPassword("");
      Swal.close();
      navigate("/");
    } catch (err) {
      // console.log(err.response.data.message);
      if (err.response) {
        Swal.fire({
          icon: "error",
          title: "Oops...",
          text: err.response.data.message,
        });
      } else {
        Swal.fire({
          icon: "error",
          title: "Oops...",
          text: "Something went wrong!",
        });
      }
    }
  };

  return (
    <>
      <section className="container">
        <div className="login">
          {/* <p ref={errRef} className={errMsg ? "errmsg" : "offscreen"} aria-live="assertive">{errMsg}</p> */}
          <h3 className="mb-3 text-center">
            KKK Dashboard <span style={{ color: "#007fff" }}>Login</span>
          </h3>
          <div className="d-flex flex-column justify-center">
            <div className="form-group mb-4 inputs">
              <label htmlFor="email" className="form-label">
                Email Address
              </label>
              <input
                type="type"
                id="email"
                ref={userRef}
                className="form-control"
                autoComplete="off"
                onChange={(e) => setEmail(e.target.value)}
                value={email}
                required
              />
              <div className="invalid-feedback">Please Enter Email</div>
            </div>

            <div className="form-group mb-2 inputs">
              <label htmlFor="password" className="form-label">
                Password
              </label>
              <input
                type={visiable}
                id="password"
                className="form-control"
                onChange={(e) => setPassword(e.target.value)}
                value={password}
                required
              />
              <button className="remove">
                {visiable === "password" ? (
                  <VisibilityOffIcon onClick={() => setVisable("text")} />
                ) : (
                  <RemoveRedEyeIcon onClick={() => setVisable("password")} />
                )}
              </button>
              <div className="invalid-feedback">Please Enter Your Password</div>
            </div>
            <div className="d-block w-100 text-end">
              <NavLink style={{ textDecoration: "none" }} to="/forgotpassword">
                <span className="text-info">Forgot Password?</span>
              </NavLink>
            </div>
            <button onClick={submitHandler} className="mt-3 loginBtns mx-auto">
              LOGIN
            </button>
          </div>
        </div>
      </section>
    </>
  );
};

export default LoginForm;
