import React, { useEffect, useState } from "react";
import "react-responsive-carousel/lib/styles/carousel.min.css"; // requires a loader
import Modal from "@mui/material/Modal";
import axios from "../../Api/axios";
import { Box, Grid } from "@mui/material";
import DeleteForeverIcon from "@mui/icons-material/DeleteForever";
import { motion } from "framer-motion";
import AddPhotoAlternateIcon from "@mui/icons-material/AddPhotoAlternate";

import "./logo.css";

import Swal from "sweetalert2";
import LogoModal from "./logoModal";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 650,
  bgcolor: "background.paper",
  border: "2px solid #000",
  boxShadow: 24,
  p: 4,
};

function CompanyLogo({ openPage }) {
  const [logos, setLogos] = useState();
  const [error, setError] = useState(false);
  const [open, setOpen] = React.useState(false);
  const [filter, setFilter] = useState([]);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

  async function fetchContents() {
    try {
      const res = await axios.get("api/v1/logos/getAll");
      // console.log(res.data[0]._id);
      setLogos(res.data);
      setFilter(res.data);
    } catch (error) {
      Swal.fire({
        title: "Error!",
        text: "something Wrong",
        icon: "error",
        confirmButtonText: "Close",
      });
    }
  }
  // console.log("filter", filter[0].filename);

  async function deleteLogo(id) {
    try {
      const res = await axios.delete(`api/v1/logos/${id}`);
      // console.log(res);
      Swal.fire({
        title: "Deleted!",
        text: "Your file has been deleted.",
        icon: "success",
      });
      fetchContents();
    } catch (error) {
      Swal.fire({
        title: "Error!",
        text: error.response.data.message,
        icon: "error",
        confirmButtonText: "Close",
      });
      console.log(error);
    }
  }

  // console.log("filters", filter);

  useEffect(() => {
    fetchContents();
  }, [open]);

  return (
    <div>
      <div className="pb-3 d-flex justify-content-between">
        <div>
          <h4 className="my-3 contactTitle">Partnership Company Logo</h4>
        </div>
        <button className="btn btn-primary my-2" onClick={handleOpen}>
          <span className="dioadsan">Add Client</span>
          <AddPhotoAlternateIcon sx={{ marginLeft: "10px" }} />
        </button>
      </div>

      <Modal
        open={open}
        // onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style}>
          <LogoModal className="" close={handleClose} />
        </Box>
      </Modal>

      {error && (
        <div>
          <p className="alert text-dark">No Logo</p>
        </div>
      )}

      <motion.div layout>
        <Grid container spacing={2}>
          {filter &&
            filter.map((filter) => (
              <Grid
                item
                component={motion.div}
                layout
                xs={6}
                sm={4}
                md={3}
                lg={2}
                xl={2}
                key={filter._id}
              >
                <div className="logo-container">
                  <img
                    src={`${process.env.REACT_APP_API_URL}/api/v1/logos/${filter.filename}`}
                    alt="logos"
                  />

                  <button
                    className="logoDel"
                    onClick={() =>
                      Swal.fire({
                        title: "Are you sure?",
                        text: "You won't be able to revert this!",
                        icon: "warning",
                        showCancelButton: true,
                        confirmButtonColor: "#3085d6",
                        cancelButtonColor: "#d33",
                        confirmButtonText: "Yes, delete it!",
                      }).then((result) => {
                        if (result.isConfirmed) {
                          deleteLogo(filter._id);
                        }
                      })
                    }
                  >
                    <DeleteForeverIcon sx={{ color: "red" }} />
                  </button>
                </div>
              </Grid>
            ))}
        </Grid>
      </motion.div>
    </div>
  );
}

export default CompanyLogo;
