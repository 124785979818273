import React, { useEffect, useState } from "react";
import "react-responsive-carousel/lib/styles/carousel.min.css"; // requires a loader
import Modal from "@mui/material/Modal";
import axios from "../../Api/axios";
import { Box, Grid } from "@mui/material";
import AddShowcase from "./AddShowcase";
import { motion } from "framer-motion";
import DomainAddIcon from "@mui/icons-material/DomainAdd";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 650,
  bgcolor: "background.paper",
  border: "2px solid #000",
  boxShadow: 24,
  paddingTop: "40px",
};

function AllShowcase({ openPage }) {
  const [contents, setContents] = useState();
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(false);
  const [open, setOpen] = React.useState(false);
  const [filter, setFilter] = useState([]);
  const [activeButton, setActiveButton] = useState("All");
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

  async function fetchContents() {
    setActiveButton("All");
    try {
      const res = await axios.get("api/v1/contents/allContents");
      // console.log(res.data.imageContents);
      setContents(res.data.imageContents);
      setFilter(res.data.imageContents);
      setLoading(false);
    } catch (error) {
      console.log(error);
      setError("no contents");
    }
  }

  function filterContent(category) {
    setActiveButton(category);
    setFilter(
      contents.filter((content) => content.textContentRef.category === category)
    );
  }

  function openDetail(id) {
    openPage(id);
  }

  useEffect(() => {
    fetchContents();
  }, [open]);

  return (
    <div className="pb-3">
      <div className="d-flex justify-content-between mb-3">
        <div>
          <button
            className={
              activeButton === "All" ? "active showcaseBtn" : "showcaseBtn"
            }
            onClick={() => fetchContents()}
          >
            <span style={{ fontWeight: "bold" }}>All</span>
          </button>
          <button
            className={
              activeButton === "Construction"
                ? "active showcaseBtn"
                : "showcaseBtn"
            }
            onClick={() => filterContent("Construction")}
          >
            <span style={{ fontWeight: "bold" }}>Construction</span>
          </button>
          <button
            className={
              activeButton === "Design" ? "active showcaseBtn" : "showcaseBtn"
            }
            onClick={() => filterContent("Design")}
          >
            <span style={{ fontWeight: "bold" }}>Design</span>
          </button>
          <button
            className={
              activeButton === "Steel Structure"
                ? "active showcaseBtn"
                : "showcaseBtn"
            }
            onClick={() => filterContent("Streel Structure")}
          >
            <span style={{ fontWeight: "bold" }}>Steel Structure</span>
          </button>
          <button
            className={
              activeButton === "Decoration"
                ? "active showcaseBtn"
                : "showcaseBtn"
            }
            onClick={() => filterContent("Decoration")}
          >
            <span style={{ fontWeight: "bold" }}>Renovation</span>
          </button>
        </div>

        <button className="btn btn-light btn-md me-2 " onClick={handleOpen}>
          <span style={{ fontWeight: "bold" }}>
            {" "}
            Add New Project <DomainAddIcon />{" "}
          </span>
        </button>
      </div>
      {loading && <h3 className="text-light text-center">Loading...</h3>}

      {!loading && (
        <div>
          <Modal
            open={open}
            onClose={handleClose}
            aria-labelledby="modal-modal-title"
            aria-describedby="modal-modal-description"
          >
            <Box sx={style}>
              <AddShowcase closeModal={handleClose} />
            </Box>
          </Modal>
          {error && (
            <div>
              <h4 className="alert text-white">No Project</h4>
            </div>
          )}
          <motion.div layout>
            <Grid container spacing={2}>
              {filter &&
                filter.map((filter) => (
                  <Grid
                    item
                    component={motion.div}
                    layout
                    xs={12}
                    sm={6}
                    md={4}
                    lg={3}
                    xl={2}
                    key={filter._id}
                  >
                    <div
                      className="project-container"
                      onClick={() => openDetail(filter.textContentRef._id)}
                    >
                      {filter && filter.images && (
                        <img
                          src={`${process.env.REACT_APP_API_URL}/api/v1/contents/${filter.images[0]?.filename}`}
                          alt="projects"
                        />
                      )}
                      {filter.textContentRef && (
                        <div>
                          <p>{filter.textContentRef.title}</p>
                          <span>{filter.textContentRef.description}</span>
                        </div>
                      )}
                    </div>
                  </Grid>
                ))}
            </Grid>
          </motion.div>
        </div>
      )}
    </div>
  );
}

export default AllShowcase;
