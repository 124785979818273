import axios from "../../Api/axios";
import { React, useState, useRef, useEffect } from "react";
import Swal from "sweetalert2";
import { motion } from "framer-motion";
import { Box, Grid } from "@mui/material";
import { styled } from "@mui/material/styles";
import { IconButton } from "@mui/material";
import "./aboutUs.css";

const VisuallyHiddenInput = styled("input")({
  clip: "rect(0 0 0 0)",
  clipPath: "inset(50%)",
  height: 1,
  overflow: "hidden",
  position: "absolute",
  bottom: 0,
  left: 0,
  whiteSpace: "nowrap",
  width: 1,
});

function AboutUs() {
  const [id, setId] = useState();
  const [logo, setLogo] = useState("");
  const [image, setImage] = useState("");
  const [address, setAddress] = useState("");
  const [editaddress, setEditAddress] = useState(false);
  const [phoneNumber, setPhoneNumber] = useState("");
  const [editphoneNumber, seteditPhoneNumber] = useState(false);
  const [officeNumber, setOfficeNumber] = useState("");
  const [editofficeNumber, seteditOfficeNumber] = useState(false);
  const [history, setHistory] = useState("");
  const [edithistory, seteditHistory] = useState(false);
  const [historyImage, setHistoryImage] = useState("");
  const [historylocalImage, setHistorylocalImage] = useState("");
  const [mission, setMission] = useState("");
  const [editmission, seteditMission] = useState(false);
  const [missionImage, setMissionImage] = useState("");
  const [missionlocalImage, setMissionlocalImage] = useState("");
  const [vision, setVision] = useState("");
  const [editvision, seteditVision] = useState(false);
  const [visionImage, setVisionImage] = useState("");
  const [visionlocalImage, setVisionlocalImage] = useState("");
  const [readOnly, setReadOnly] = useState(true);
  const fileInputRef = useRef(null);

  const [filter, setFilter] = useState([]);

  function ImageHandler(e) {
    setImage(e.target.files[0]);
    console.log(fileInputRef.current.files[0]);
  }

  async function onKeyDownHandler(e) {
    if (e.key === "Enter") {
      ApiHandler();
    }
  }

  async function logoUpdateHandler() {
    const data = {
      logo: image,
    };
    try {
      await axios
        .put(`api/v1/about-us/${id}`, data, {
          headers: { "Content-type": "multipart/form-data" },
        })
        .then((res) => {
          console.log(res);
          setImage("");
          fetchContents();
          Swal.fire({
            text: "Uploaded",
            icon: "success",
          });
        });
    } catch (error) {
      Swal.fire({
        title: "Error!",
        text: error.response.data.message,
        icon: "error",
        confirmButtonText: "Close",
      });
    }
  }

  async function historyImageUpdateHandler() {
    const data = {
      historyImage: historylocalImage,
    };
    try {
      await axios
        .put(`api/v1/about-us/${id}`, data, {
          headers: { "Content-type": "multipart/form-data" },
        })
        .then((res) => {
          console.log(res);
          setHistorylocalImage("");
          fetchContents();
          Swal.fire({
            text: "Uploaded",
            icon: "success",
          });
        });
    } catch (error) {
      Swal.fire({
        title: "Error!",
        text: error.response.data.messsage,
        icon: "error",
        confirmButtonText: "Close",
      });
    }
  }

  async function missionImageUpdateHandler() {
    const data = {
      missionImage: missionlocalImage,
    };
    try {
      await axios
        .put(`api/v1/about-us/${id}`, data, {
          headers: { "Content-type": "multipart/form-data" },
        })
        .then((res) => {
          console.log(res);
          setMissionlocalImage("");
          fetchContents();
          Swal.fire({
            text: "Uploaded",
            icon: "success",
          });
        });
    } catch (error) {
      Swal.fire({
        title: "Error!",
        text: error.response.data.message,
        icon: "error",
        confirmButtonText: "Close",
      });
    }
  }

  async function visionImageUpdateHandler() {
    const data = {
      visionImage: visionlocalImage,
    };
    try {
      await axios
        .put(`api/v1/about-us/${id}`, data, {
          headers: { "Content-type": "multipart/form-data" },
        })
        .then((res) => {
          console.log(res);
          setVisionlocalImage("");
          fetchContents();
          Swal.fire({
            text: "Uploaded",
            icon: "success",
          });
        });
    } catch (error) {
      Swal.fire({
        title: "Error!",
        text: error.response.data.message,
        icon: "error",
        confirmButtonText: "Close",
      });
    }
  }

  // To upload the content
  async function ApiHandler() {
    const data = {
      address: address,
      phone_number: phoneNumber,
      office_number: officeNumber,
      history: history,
      mission: mission,
      vision: vision,
    };
    // console.log(data);
    try {
      await axios
        .put(`api/v1/about-us/${id}`, data, {
          headers: { "Content-type": "application/json" },
        })
        .then((res) => {
          console.log(res);
          setReadOnly(true);
          fetchContents();
          Swal.fire({
            text: "Uploaded",
            icon: "success",
          });
        });
    } catch (error) {
      Swal.fire({
        title: "Error!",
        text: error.response.data.message,
        icon: "error",
        confirmButtonText: "Close",
      });
    }
  }
  // Get the content
  async function fetchContents() {
    try {
      const res = await axios.get("api/v1/about-us");
      console.log(res.data);
      setId(res.data.aboutUs[0]._id);
      setLogo(res.data.aboutUs[0].logo);
      setHistoryImage(res.data.aboutUs[0].historyImage);
      setMissionImage(res.data.aboutUs[0].missionImage);
      setVisionImage(res.data.aboutUs[0].visionImage);
      setAddress(res.data.aboutUs[0].address);
      setPhoneNumber(res.data.aboutUs[0].phone_number);
      setOfficeNumber(res.data.aboutUs[0].office_number);
      setHistory(res.data.aboutUs[0].history);
      setMission(res.data.aboutUs[0].mission);
      setVision(res.data.aboutUs[0].vision);
      console.log("phone", phoneNumber);

      setFilter(res.data.aboutUs);
    } catch (error) {
      Swal.fire({
        title: "Error!",
        text: "Something Wrong",
        icon: "error",
        confirmButtonText: "Close",
      });
    }
  }

  useEffect(() => {
    fetchContents();
  }, []);

  return (
    <div className="px-3 about-container">
      <div className="d-flex justify-content-between">
        <h5 className="mb-3 contactTitle">About Company Info</h5>
      </div>

      <motion.div layout>
        <Grid>
          <Grid item component={motion.div} layout key={filter._id}>
            {/* Contact Info  */}
            <div className="bg-white card-header mt-3 p-3">
              <div>
                <h4 className="mb-3 card-head">Company Contact Info</h4>
              </div>

              <div className="row d-flex justify-content-between">
                {/* Logo and Office Ph Number */}
                <div className="col-6">
                  <div className="sub-header mb-1 py-2">
                    <div className="py-1 px-3">
                      <h5 className="text-white">Company Logo</h5>
                    </div>

                    <div className="aboutus py-2 d-flex justify-content-between">
                      <div className="aboutus-img mb-1 text-white ms-3">
                        {image ? (
                          <img
                            src={URL.createObjectURL(image)}
                            alt="contact-info"
                          />
                        ) : (
                          <img
                            src={`${process.env.REACT_APP_API_URL}/api/v1/about-us/${logo.filename}`}
                            alt="contact-info"
                          />
                        )}
                      </div>

                      {image ? (
                        <div className="me-3">
                          <button
                            className="buttons bg-danger me-2"
                            onClick={() => setImage("")}
                          >
                            cancel
                          </button>
                          <button
                            className="buttons"
                            onClick={() => {
                              logoUpdateHandler();
                            }}
                          >
                            upload
                          </button>
                        </div>
                      ) : (
                        <IconButton
                          component="label"
                          sx={{
                            backgroundColor: "#007fff",
                            color: "#fff",
                            fontSize: "12px",
                            fontWeight: "bold",
                            borderRadius: "5px",
                            padding: "0px 10px",
                            margin: "0px 10px",
                          }}
                          role={undefined}
                        >
                          Change Logo
                          <VisuallyHiddenInput
                            type="file"
                            ref={fileInputRef}
                            onChange={ImageHandler}
                          />
                        </IconButton>
                      )}
                    </div>
                  </div>
                  <div className="sub-header mb-2 pt-2 pb-4 mt-3">
                    <div className="py-2 d-flex justify-content-between">
                      <div className="px-3">
                        <h5 className="text-white">Office Phone Number</h5>
                      </div>

                      {!editofficeNumber && (
                        <button
                          className="buttons me-3 mb-1"
                          onClick={() => {
                            seteditOfficeNumber(true);
                          }}
                        >
                          <span>Edit</span>
                        </button>
                      )}
                      {editofficeNumber && (
                        <div>
                          <button
                            className="btn btn-sm btn-success me-3 mb-1"
                            onClick={() => {
                              seteditOfficeNumber(false);
                              ApiHandler();
                            }}
                          >
                            <span>Save</span>
                          </button>
                          <button
                            className="btn btn-sm btn-danger me-3 mb-1"
                            onClick={() => {
                              seteditOfficeNumber(false);
                            }}
                          >
                            <span>Cancel</span>
                          </button>
                        </div>
                      )}
                    </div>
                    <div className="mx-3">
                      <input
                        type="text"
                        value={officeNumber}
                        className="form-control bg-dark text-white"
                        id="exampleFormControlInput1"
                        placeholder="Enter Phone Number"
                        onChange={(event) => {
                          setOfficeNumber(event.target.value);
                        }}
                        onKeyDown={onKeyDownHandler}
                        readOnly={!editofficeNumber}
                      />
                    </div>
                  </div>
                </div>

                {/* Address and Contact Ph Number */}
                <div className="col-6">
                  <div className="sub-header mb-2 pt-2 pb-4">
                    <div className="py-2 d-flex justify-content-between">
                      <div className="px-3">
                        <h5 className="text-white">Office Address</h5>
                      </div>
                      <div>
                        {!editaddress && (
                          <button
                            className="buttons me-3 mb-1"
                            onClick={() => {
                              setReadOnly(!readOnly);
                              setEditAddress(true);
                              console.log("address", typeof address);
                            }}
                          >
                            <span>Edit</span>
                          </button>
                        )}
                        {editaddress && (
                          <div>
                            <button
                              className="btn btn-sm btn-success me-3 mb-1"
                              onClick={() => {
                                setReadOnly(!readOnly);
                                setEditAddress(false);
                                ApiHandler();
                              }}
                            >
                              <span>Save</span>
                            </button>
                            <button
                              className="btn btn-sm btn-danger me-3 mb-1"
                              onClick={() => {
                                setReadOnly(!readOnly);
                                setEditAddress(false);
                              }}
                            >
                              <span>Cancel</span>
                            </button>
                          </div>
                        )}
                      </div>
                    </div>
                    <div className="mx-3">
                      <input
                        type="text"
                        value={address}
                        className="form-control bg-dark text-white"
                        id="exampleFormControlInput1"
                        placeholder={address}
                        onChange={(event) => {
                          setAddress(event.target.value);
                        }}
                        onKeyDown={onKeyDownHandler}
                        readOnly={readOnly}
                      />
                    </div>
                  </div>

                  <div className="sub-header mb-2 pt-2 pb-4">
                    <div className="py-2 d-flex justify-content-between">
                      <div className="px-3">
                        <h5 className="text-white">Contact Phone Number</h5>
                      </div>

                      {!editphoneNumber && (
                        <button
                          className="buttons me-3 mb-1"
                          onClick={() => {
                            setReadOnly(!readOnly);
                            seteditPhoneNumber(true);
                          }}
                        >
                          <span>Edit</span>
                        </button>
                      )}
                      {editphoneNumber && (
                        <div>
                          <button
                            className="btn btn-sm btn-success me-3 mb-1"
                            onClick={() => {
                              seteditPhoneNumber(false);
                              ApiHandler();
                            }}
                          >
                            <span>Save</span>
                          </button>
                          <button
                            className="btn btn-sm btn-danger me-3 mb-1"
                            onClick={() => {
                              seteditPhoneNumber(false);
                            }}
                          >
                            <span>Cancel</span>
                          </button>
                        </div>
                      )}
                    </div>
                    <div className="mx-3">
                      <input
                        type="text"
                        value={phoneNumber}
                        className="form-control bg-dark text-white"
                        id="exampleFormControlInput1"
                        placeholder="Enter Phone Number"
                        onChange={(event) => {
                          setPhoneNumber(event.target.value);
                        }}
                        onKeyDown={onKeyDownHandler}
                        readOnly={!editphoneNumber}
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>

            {/* History Info */}
            <div className="bg-white card-header mt-4 p-3">
              <div>
                <h4 className="mb-3 card-head">Company History Info</h4>
              </div>

              <div className="row d-flex justify-content-between">
                <div className="col-6">
                  <div className="sub-header mb-1 py-2">
                    <div className="py-2 d-flex justify-content-between">
                      <div className="px-3">
                        <h5 className="text-white">Related Image</h5>
                      </div>
                      {historylocalImage ? (
                        <div>
                          <button
                            className="buttons bg-danger me-1"
                            onClick={() => setHistorylocalImage("")}
                          >
                            cancel
                          </button>
                          <button
                            className="buttons"
                            onClick={() => {
                              historyImageUpdateHandler();
                            }}
                          >
                            upload
                          </button>
                        </div>
                      ) : (
                        <IconButton
                          component="label"
                          sx={{
                            backgroundColor: "#007fff",
                            color: "#fff",
                            fontSize: "12px",
                            fontWeight: "bold",
                            borderRadius: "5px",
                            padding: "0px 10px",
                            margin: "0px 10px",
                          }}
                          role={undefined}
                        >
                          Change image
                          <VisuallyHiddenInput
                            type="file"
                            ref={fileInputRef}
                            onChange={(event) => {
                              setHistorylocalImage(event.target.files[0]);
                            }}
                          />
                        </IconButton>
                      )}
                    </div>

                    <div className="mx-2 mx-lg-5">
                      <div className="history-img mb-1 text-white">
                        {historylocalImage ? (
                          <img
                            src={URL.createObjectURL(historylocalImage)}
                            alt="contact-info"
                          />
                        ) : (
                          <img
                            src={`${process.env.REACT_APP_API_URL}/api/v1/about-us/${historyImage.filename}`}
                            alt="contact-info"
                          />
                        )}
                      </div>
                    </div>
                  </div>
                </div>

                <div className="col-6">
                  <div className="sub-header mb-1 py-2">
                    <div className="py-2 d-flex justify-content-between">
                      <div className="px-3">
                        <h5 className="text-white">Description</h5>
                      </div>

                      {!edithistory && (
                        <button
                          className="buttons me-3 mb-1"
                          onClick={() => {
                            seteditHistory(true);
                          }}
                        >
                          <span>Edit</span>
                        </button>
                      )}
                      {edithistory && (
                        <div>
                          <button
                            className="btn btn-sm btn-success me-3 mb-1"
                            onClick={() => {
                              seteditHistory(false);
                              ApiHandler();
                            }}
                          >
                            <span>Save</span>
                          </button>
                          <button
                            className="btn btn-sm btn-danger me-3 mb-1"
                            onClick={() => {
                              seteditHistory(false);
                            }}
                          >
                            <span>Cancel</span>
                          </button>
                        </div>
                      )}
                    </div>
                    <div className="mx-3">
                      <textarea
                        value={history}
                        className="form-control mt-1 bg-dark text-white"
                        id=""
                        rows="5"
                        onChange={(event) => {
                          setHistory(event.target.value);
                        }}
                        onKeyDown={onKeyDownHandler}
                        readOnly={!edithistory}
                      ></textarea>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            {/* Mission Info */}
            <div className="bg-white card-header mt-4 p-3">
              <div>
                <h4 className="mb-3 card-head">Company Mission Info</h4>
              </div>

              <div className="row d-flex justify-content-between">
                <div className="col-6">
                  <div className="sub-header mb-1 py-2">
                    <div className="py-2 d-flex justify-content-between">
                      <div className="px-3">
                        <h5 className="text-white">Related Image</h5>
                      </div>
                      {missionlocalImage ? (
                        <div>
                          <button
                            className="buttons bg-danger me-1"
                            onClick={() => setMissionlocalImage("")}
                          >
                            cancel
                          </button>
                          <button
                            className="buttons"
                            onClick={() => {
                              missionImageUpdateHandler();
                            }}
                          >
                            upload
                          </button>
                        </div>
                      ) : (
                        <IconButton
                          component="label"
                          sx={{
                            backgroundColor: "#007fff",
                            color: "#fff",
                            fontSize: "12px",
                            fontWeight: "bold",
                            borderRadius: "5px",
                            padding: "0px 10px",
                            margin: "0px 10px",
                          }}
                          role={undefined}
                        >
                          Change image
                          <VisuallyHiddenInput
                            type="file"
                            ref={fileInputRef}
                            onChange={(event) => {
                              setMissionlocalImage(event.target.files[0]);
                            }}
                          />
                        </IconButton>
                      )}
                    </div>

                    <div className="mx-2 mx-lg-5">
                      <div className="history-img mb-1 text-white">
                        {missionlocalImage ? (
                          <img
                            src={URL.createObjectURL(missionlocalImage)}
                            alt="contact-info"
                          />
                        ) : (
                          <img
                            src={`${process.env.REACT_APP_API_URL}/api/v1/about-us/${missionImage.filename}`}
                            alt="contact-info"
                          />
                        )}
                      </div>
                    </div>
                  </div>
                </div>

                <div className="col-6">
                  <div className="sub-header mb-1 py-2">
                    <div className="py-2 d-flex justify-content-between">
                      <div className="px-3">
                        <h5 className="text-white">Description</h5>
                      </div>

                      {!editmission && (
                        <button
                          className="buttons me-3 mb-1"
                          onClick={() => {
                            seteditMission(true);
                          }}
                        >
                          <span>Edit</span>
                        </button>
                      )}
                      {editmission && (
                        <div>
                          <button
                            className="btn btn-sm btn-success me-3 mb-1"
                            onClick={() => {
                              seteditMission(false);
                              ApiHandler();
                            }}
                          >
                            <span>Save</span>
                          </button>
                          <button
                            className="btn btn-sm btn-danger me-3 mb-1"
                            onClick={() => {
                              seteditMission(false);
                            }}
                          >
                            <span>Cancel</span>
                          </button>
                        </div>
                      )}
                    </div>
                    <div className="mx-3">
                      <textarea
                        value={mission}
                        className="form-control mt-1 bg-dark text-white"
                        id=""
                        rows="5"
                        onChange={(event) => {
                          setMission(event.target.value);
                        }}
                        onKeyDown={onKeyDownHandler}
                        readOnly={!editmission}
                      ></textarea>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            {/* Vision Info */}
            <div className="bg-white card-header mt-4 p-3">
              <div>
                <h4 className="mb-3 card-head">Company Vision Info</h4>
              </div>

              <div className="row d-flex justify-content-between">
                <div className="col-6">
                  <div className="sub-header mb-1 py-2">
                    <div className="py-2 d-flex justify-content-between">
                      <div className="px-3">
                        <h5 className="text-white">Related Image</h5>
                      </div>
                      {visionlocalImage ? (
                        <div>
                          <button
                            className="buttons bg-danger me-1"
                            onClick={() => setVisionlocalImage("")}
                          >
                            cancel
                          </button>
                          <button
                            className="buttons"
                            onClick={() => {
                              visionImageUpdateHandler();
                            }}
                          >
                            upload
                          </button>
                        </div>
                      ) : (
                        <IconButton
                          component="label"
                          sx={{
                            backgroundColor: "#007fff",
                            color: "#fff",
                            fontSize: "12px",
                            fontWeight: "bold",
                            borderRadius: "5px",
                            padding: "0px 10px",
                            margin: "0px 10px",
                          }}
                          role={undefined}
                        >
                          Change image
                          <VisuallyHiddenInput
                            type="file"
                            ref={fileInputRef}
                            onChange={(event) => {
                              setVisionlocalImage(event.target.files[0]);
                            }}
                          />
                        </IconButton>
                      )}
                    </div>

                    <div className="mx-2 mx-lg-5">
                      <div className="history-img mb-1 text-white">
                        {visionlocalImage ? (
                          <img
                            src={URL.createObjectURL(visionlocalImage)}
                            alt="contact-info"
                          />
                        ) : (
                          <img
                            src={`${process.env.REACT_APP_API_URL}/api/v1/about-us/${visionImage.filename}`}
                            alt="contact-info"
                          />
                        )}
                      </div>
                    </div>
                  </div>
                </div>

                <div className="col-6">
                  <div className="sub-header mb-1 py-2">
                    <div className="py-2 d-flex justify-content-between">
                      <div className="px-3">
                        <h5 className="text-white">Description</h5>
                      </div>

                      {!editvision && (
                        <button
                          className="buttons me-3 mb-1"
                          onClick={() => {
                            seteditVision(true);
                          }}
                        >
                          <span>Edit</span>
                        </button>
                      )}
                      {editvision && (
                        <div>
                          <button
                            className="btn btn-sm btn-success me-3 mb-1"
                            onClick={() => {
                              seteditVision(false);
                              ApiHandler();
                            }}
                          >
                            <span>Save</span>
                          </button>
                          <button
                            className="btn btn-sm btn-danger me-3 mb-1"
                            onClick={() => {
                              seteditVision(false);
                            }}
                          >
                            <span>Cancel</span>
                          </button>
                        </div>
                      )}
                    </div>
                    <div className="mx-3">
                      <textarea
                        value={vision}
                        className="form-control mt-1 bg-dark text-white"
                        id=""
                        rows="5"
                        onChange={(event) => {
                          setVision(event.target.value);
                        }}
                        onKeyDown={onKeyDownHandler}
                        readOnly={!editvision}
                      ></textarea>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </Grid>
        </Grid>
      </motion.div>
    </div>
  );
}
export default AboutUs;
