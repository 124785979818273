import React, { useEffect, useRef, useState } from "react";
import axios from "../../Api/axios";
import "../../Components/achievement.css";
import Swal from "sweetalert2";

import { Box, Grid } from "@mui/material";
import finished from "./../../Images/finish.png";
import staff from "./../../Images/staff.png";
import RemoveIcon from "@mui/icons-material/Remove";
import backgroudfinidh from "./../../Images/finish.avif";
import backgroudstaff from "./../../Images/staff.avif";
import RestartAltIcon from "@mui/icons-material/RestartAlt";
import AddCircleOutlineIcon from "@mui/icons-material/AddCircleOutline";

function CountNumber() {
  const [project, setProject] = useState(0);
  const [staffcount, setStaffCounts] = useState(0);

  const minus = (number, setnumber) => {
    if (number > 0) {
      setnumber(number - 1);
    }
  };

  // function to fetch projects api
  async function fetchPjNumbers() {
    try {
      const res = await axios.get("api/v1/achievements/projects");
      // console.log(res.data);
      setProject(res.data.finishedProjects * 1);
    } catch (error) {
      // console.log(error);
    }
  }

  useEffect(() => {
    fetchPjNumbers();
  }, []);

  // function to fetch staffs api
  async function fetchStaffNumbers() {
    try {
      const res = await axios.get("api/v1/achievements/staffs");
      // console.log(res.data.staff * 1);
      setStaffCounts(res.data.staff * 1);
    } catch (error) {
      // console.log(error);
    }
  }

  useEffect(() => {
    fetchStaffNumbers();
  }, []);

  // Function to put projects api
  async function ProjectHandler() {
    const projectData = {
      finishedProjects: project,
    };
    await axios.put("api/v1/achievements/projects", projectData).then((res) => {
      if (res.status === 200) {
        Swal.fire({
          text: "Project Count Updated",
          icon: "success",
        });
      } else {
        Swal.fire({
          text: "Something went wrong",
          icon: "error",
        });
      }
    });
  }

  // Function to put staffs api
  async function StaffHandler() {
    const staffData = {
      staff: staffcount,
    };
    await axios.put("api/v1/achievements/staffs", staffData).then((res) => {
      if (res.status === 200) {
        Swal.fire({
          text: "Staff Count Updated",
          icon: "success",
        });
      } else {
        Swal.fire({
          text: "Something went wrong",
          icon: "error",
        });
      }
    });
  }

  return (
    <div className="containers">
      <h4 className="mb-4 contactTitle">Company Achievements</h4>

      <div className="row my-3">
        <div className="col-6">
          <div className="bg-dark px-4 rounded">
            <h5 className="text-white py-3">Finished Projects Count</h5>
            <div className="row pj-cards">
              <div className="col-8 bg-area mb-4">
                <div className="project-number-container">
                  <img src={backgroudfinidh} alt="background" />
                  <div className="project-number">
                    <img src={finished} alt="finished" />
                    <p className="number">{project}</p>
                    <p className="text">Finished Projects</p>
                  </div>
                </div>
              </div>

              <div className="col-3 btns">
                <button
                  className="btn btn-sm btn-secondary mb-2"
                  onClick={() => setProject(project + 1)}
                >
                  Increment
                  <AddCircleOutlineIcon />
                </button>
                <button
                  className="btn btn-sm btn-light my-3"
                  onClick={() => minus(project, setProject)}
                >
                  Decrement
                  <RemoveIcon />
                </button>
                <button
                  className="btn btn-sm btn-secondary my-2"
                  onClick={() => setProject(0)}
                >
                  Reset
                  <RestartAltIcon />
                </button>

                <button
                  className="btn btn-primary btn-sm mt-5"
                  onClick={ProjectHandler}
                >
                  Upload
                </button>
              </div>
            </div>
          </div>
        </div>

        <div className="col-6">
          <div className="bg-dark px-4 rounded">
            <h5 className="text-white py-3">Staff Count</h5>
            <div className="row pj-cards">
              <div className="col-8 bg-area mb-4">
                <div className="project-number-container">
                  <img src={backgroudstaff} alt="background" />
                  <div className="project-number">
                    <img src={staff} alt="finished" />
                    <p className="number">{staffcount}</p>
                    <p className="text">Staff Members</p>
                  </div>
                </div>
              </div>

              <div className="col-3 btns">
                <button
                  className="btn btn-sm btn-secondary mb-2"
                  onClick={() => setStaffCounts(staffcount + 1)}
                >
                  Increment
                  <AddCircleOutlineIcon />
                </button>
                <button
                  className="btn btn-sm btn-secondary my-2"
                  onClick={() => minus(staffcount, setStaffCounts)}
                >
                  Decrement
                  <RemoveIcon />
                  <span>
                    <i className="minus"></i>
                  </span>
                </button>
                <button
                  className="btn btn-sm btn-secondary my-2"
                  onClick={() => setStaffCounts(0)}
                >
                  Reset
                  <RestartAltIcon />
                </button>

                <button
                  className="btn btn-primary btn-sm mt-5"
                  onClick={StaffHandler}
                >
                  Upload
                </button>
              </div>
            </div>
          </div>
        </div>

        {/* <div className="col-6">
          <div className="card bg-info text-center px-3">
            <div className="card-body">
              <h5>Staffs</h5>
              <h4>{staffcount}</h4>
              <button
                className="btn btn-sm btn-success mx-1 my-2"
                onClick={() => setStaffCounts(staffcount + 1)}
              >
                Increment
              </button>
              <button
                className="btn btn-sm btn-danger mx-1 my-2"
                onClick={() => minus(staffcount, setStaffCounts)}
              >
                Decrement
              </button>
              <button
                className="btn btn-sm btn-secondary mx-1 my-2"
                onClick={() => setStaffCounts(0)}
              >
                Reset
              </button>

              <button className="btn btn-primary btn-sm" onClick={StaffHandler}>
                Upload
              </button>
            </div>
          </div>
        </div> */}
      </div>
    </div>
  );
}

export default CountNumber;
