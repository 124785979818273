import React from "react";
import { NavLink } from "react-router-dom";
import BurstModeIcon from "@mui/icons-material/BurstMode";
import ApartmentIcon from "@mui/icons-material/Apartment";
import HandshakeIcon from "@mui/icons-material/Handshake";
import MarkunreadMailboxIcon from "@mui/icons-material/MarkunreadMailbox";
import ConstructionIcon from "@mui/icons-material/Construction";
import VoiceChatIcon from "@mui/icons-material/VoiceChat";
import logo from "./../../Images/logo.png";

import "./navbar.css";

function Navbar() {
  const logoutHandlder = () => {
    localStorage.clear();
    window.location.href = "/login";
  };

  return (
    <>
      <nav className="navbar bg-dark fixed-top py-3">
        <div className="container-fluid">
          <button
            className="navbar-toggler text-white"
            type="button"
            data-bs-toggle="offcanvas"
            data-bs-target="#offcanvasNavbar"
            aria-controls="offcanvasNavbar"
            aria-label="Toggle navigation"
          >
            <span className="navbar-toggler-icon bg-white"></span>
          </button>
          <button
            className="btn btn-outline-light btn-lg"
            onClick={logoutHandlder}
          >
            <span style={{ fontWeight: "bold" }}>Logout</span>
          </button>
          <div
            className="offcanvas offcanvas-start"
            tabIndex="0"
            id="offcanvasNavbar"
            aria-labelledby="offcanvasNavbarLabel"
            style={{ width: "300px" }}
          >
            <div className="offcanvas-header">
              <h5 className="offcanvas-title" id="offcanvasNavbarLabel">
                KKK
              </h5>
              <button
                type="button"
                className="btn-close"
                data-bs-dismiss="offcanvas"
                aria-label="Close"
              ></button>
            </div>
            <div className="offcanvas-body">
              <ul className="navbar-nav bg-dark justify-content-start rounded py-4">
                <li className="nav-item" data-bs-dismiss="offcanvas">
                  <NavLink className="navlinks" to="/">
                    <BurstModeIcon sx={{ mx: 1 }} />
                    Banner
                  </NavLink>
                </li>

                <li className="nav-item" data-bs-dismiss="offcanvas">
                  <NavLink className="navlinks" to="/count">
                    <ApartmentIcon sx={{ mx: 1 }} />
                    Company Achievement
                  </NavLink>
                </li>
                <li className="nav-item" data-bs-dismiss="offcanvas">
                  <NavLink className="navlinks" to="/logo">
                    <HandshakeIcon sx={{ mx: 1 }} />
                    Partnership Logo
                  </NavLink>
                </li>

                <li className="nav-item" data-bs-dismiss="offcanvas">
                  <NavLink className="navlinks" to="/contactus">
                    <MarkunreadMailboxIcon sx={{ mx: 1 }} />
                    Contact MailBox
                  </NavLink>
                </li>
                <li className="nav-item" data-bs-dismiss="offcanvas">
                  <NavLink className="navlinks" to="/showcase">
                    <ConstructionIcon sx={{ mx: 1 }} />
                    Finished Project
                  </NavLink>
                </li>
                <li className="nav-item" data-bs-dismiss="offcanvas">
                  <NavLink className="navlinks" to="/aboutus">
                    <img src={logo} className="mx-2 px-1" alt="aboutus" />
                    About Company
                  </NavLink>
                </li>
                <li className="nav-item" data-bs-dismiss="offcanvas">
                  <NavLink className="navlinks" to="/testimonial">
                    <VoiceChatIcon sx={{ mx: 1 }} />
                    Testimonial
                  </NavLink>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </nav>
    </>
  );
}

export default Navbar;
