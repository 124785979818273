import React, { useEffect, useState } from "react";
import "react-responsive-carousel/lib/styles/carousel.min.css"; // requires a loader
import Modal from "@mui/material/Modal";
import axios from "../../Api/axios";
import { Box, Grid } from "@mui/material";
import DeleteForeverIcon from "@mui/icons-material/DeleteForever";
import { motion } from "framer-motion";
import "./testimonial.css";
import AddPhotoAlternateIcon from "@mui/icons-material/AddPhotoAlternate";

import Swal from "sweetalert2";
import ModalTest from "./ModalTest";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 650,
  bgcolor: "background.paper",
  border: "2px solid #000",
  boxShadow: 24,
  p: 4,
};

function CusTestimonial({ openPage }) {
  // const [profile, setProfile] = useState("");
  // const [error, setError] = useState(false);
  const [open, setOpen] = React.useState(false);
  const [filter, setFilter] = useState([]);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

  async function fetchContents() {
    try {
      const res = await axios.get("api/v1/testimonials");
      console.log(res);
      // setProfile(res.data);
      setFilter(res.data);
    } catch (error) {
      Swal.fire({
        title: "Error!",
        text: "Do you want to continue",
        icon: "error",
        confirmButtonText: "Cool",
      });
    }
  }

  // console.log("filter", filter[0].profile.filename);
  // console.log("filter", filter[0].company);

  async function deleteContent(id) {
    try {
      const res = await axios.delete(`api/v1/testimonials/${id}`);
      // console.log(res);
      Swal.fire({
        title: "Deleted!",
        text: "Your file has been deleted.",
        icon: "success",
      });
      fetchContents();
    } catch (error) {
      Swal.fire({
        title: "Error!",
        text: "Something Wrong",
        icon: "error",
        confirmButtonText: "Close",
      });
      console.log(error);
    }
  }

  // console.log("filters", filter[0].filename);

  useEffect(() => {
    fetchContents();
  }, [open]);

  return (
    <div>
      <div className="pb-3 d-flex justify-content-between">
        <div>
          <h4 className="my-3 contactTitle">Customer's Testimonial</h4>
        </div>
        <button className="btn btn-primary my-2" onClick={handleOpen}>
          Add Testmonial
          <AddPhotoAlternateIcon sx={{ marginLeft: "10px" }} />
        </button>
      </div>

      <Modal
        open={open}
        // onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style}>
          <ModalTest className="" close={handleClose} />
        </Box>
      </Modal>

      <motion.div layout>
        <Grid container spacing={3}>
          {filter &&
            filter.map((filter) => (
              <Grid
                item
                component={motion.div}
                layout
                xs={12}
                sm={6}
                md={4}
                lg={4}
                xl={2}
                key={filter._id}
              >
                <div className="testimonial-container">
                  <div className="testimonial-item">
                    <div className="info">
                      <img
                        src={`${process.env.REACT_APP_API_URL}/api/v1/testimonials/${filter.profile.filename}`}
                        alt="projects"
                      />
                      <div className="text-box">
                        <h4 className="name">{filter.name}</h4>
                        <span className="company">{filter.company}</span>
                      </div>

                      <div className="">
                        <button
                          className="contentDel"
                          onClick={() =>
                            Swal.fire({
                              title: "Are you sure?",
                              text: "You won't be able to revert this!",
                              icon: "warning",
                              showCancelButton: true,
                              confirmButtonColor: "#3085d6",
                              cancelButtonColor: "#d33",
                              confirmButtonText: "Yes, delete it!",
                            }).then((result) => {
                              if (result.isConfirmed) {
                                deleteContent(filter._id);
                              }
                            })
                          }
                        >
                          <DeleteForeverIcon sx={{ color: "red" }} />
                        </button>
                      </div>
                    </div>
                    <div>
                      <p>{filter.body}</p>
                    </div>
                  </div>
                </div>
              </Grid>
            ))}
        </Grid>
      </motion.div>
    </div>
  );
}

export default CusTestimonial;
