import axios from "../../Api/axios";
import React, { useContext, useState, useRef } from "react";
import "../../Components/bannerImg.css";
import Swal from "sweetalert2";

function ImageUpload({ close }) {
  const [images, setImages] = useState("");
  const fileInputRef = useRef(null);

  function ImageHandler(e) {
    setImages(e.target.files[0]);
  }

  // To Delete the image
  function deleteImages() {
    setImages(null);
  }

  function closeModal() {
    // console.log("close");
    close();
  }

  async function ApiHandler() {
    const data = {
      image: images,
    };
    console.log(data);
    try {
      await axios
        .post("api/v1/banners/upload", data, {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        })
        .then((res) => {
          console.log(res);
          close();
          Swal.fire({
            text: "Image Uploaded",
            icon: "success",
          });
        });
    } catch (error) {
      close();
      Swal.fire({
        title: "Error!",
        text: error.response.data.message,
        icon: "error",
        confirmButtonText: "Cool",
      });
    }
  }

  return (
    <div className="px-3">
      <div className="d-flex justify-content-between">
        <h5 className="mb-3">Home Page Banner Carousel Review</h5>
        <button className="delete text-black" onClick={closeModal}>
          Close
        </button>
      </div>
      <div className="img-area border rounded">
        {images && (
          <div className="images">
            <img src={URL.createObjectURL(images)} alt={images} />
          </div>
        )}
      </div>

      <div className="d-flex justify-content-between pt-3">
        <div className=" bg-black p-2 rounded">
          <label htmlFor="file" className="text-white">
            Background Image
          </label>
          <input
            type="file"
            name="file"
            className="file my-1 rounded"
            ref={fileInputRef}
            onChange={ImageHandler}
          />
        </div>

        <div className=" ms-4 d-flex justify-content-end">
          <button
            className="btn btn-primary btn-md bg-white text-danger"
            onClick={deleteImages}
          >
            Delete
          </button>

          <button className="btn btn-primary btn-md ms-3" onClick={ApiHandler}>
            Upload
          </button>
        </div>
      </div>
    </div>
  );
}

export default ImageUpload;
