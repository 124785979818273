import React, { useEffect, useState } from "react";
import "react-responsive-carousel/lib/styles/carousel.min.css"; // requires a loader
import Modal from "@mui/material/Modal";
import axios from "../../Api/axios";
import { Box, Grid } from "@mui/material";
import ModalBox from "./ModalBox";
import DeleteForeverIcon from "@mui/icons-material/DeleteForever";
import { motion } from "framer-motion";
import AddPhotoAlternateIcon from "@mui/icons-material/AddPhotoAlternate";

import "./banner.css";
import Swal from "sweetalert2";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 650,
  bgcolor: "background.paper",
  border: "2px solid #000",
  boxShadow: 24,
  p: 4,
};

function ImageUpload({ openPage }) {
  const [contents, setContents] = useState();
  const [error, setError] = useState(false);
  const [open, setOpen] = React.useState(false);
  const [filter, setFilter] = useState([]);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

  async function fetchContents() {
    try {
      const res = await axios.get("api/v1/banners/images");
      console.log(res.data);
      setContents(res.data);
      setFilter(res.data);
    } catch (error) {}
  }

  async function deleteContent(id) {
    try {
      const res = await axios.delete(`api/v1/banners/${id}`);
      console.log(res);
      Swal.fire({
        title: "Deleted!",
        text: "Your file has been deleted.",
        icon: "success",
      });
      fetchContents();
    } catch (error) {
      Swal.fire({
        title: "Error!",
        text: error.response.data.message,
        icon: "error",
        confirmButtonText: "Ok",
      });
      console.log(error);
    }
  }

  useEffect(() => {
    fetchContents();
  }, [open]);

  return (
    <div>
      <div className="pb-3 d-flex justify-content-between">
        <div>
          {/* <h4 className="contactTitle">Contact Mailbox</h4> */}
          <h4 className="my-3 contactTitle">Company Banner Images</h4>
        </div>
        <button className="btn btn-primary my-2" onClick={handleOpen}>
          Add Image
          <AddPhotoAlternateIcon sx={{ marginLeft: "10px" }} />
        </button>
      </div>
      <Modal
        open={open}
        // onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style}>
          <ModalBox close={handleClose} />
        </Box>
      </Modal>
      {error && (
        <div>
          <p className="alert text-dark">No Project</p>
        </div>
      )}
      <motion.div layout>
        <Grid container spacing={2}>
          {filter &&
            filter.map((filter) => (
              <Grid
                item
                component={motion.div}
                layout
                xs={12}
                sm={6}
                md={4}
                lg={3}
                xl={2}
                key={filter._id}
              >
                <div className="project-container">
                  <img
                    src={`${process.env.REACT_APP_API_URL}/api/v1/banners/${filter.filename}`}
                    alt="projects"
                  />

                  <button
                    className="bannerDel"
                    onClick={() =>
                      Swal.fire({
                        title: "Are you sure?",
                        text: "You won't be able to revert this!",
                        icon: "warning",
                        showCancelButton: true,
                        confirmButtonColor: "#3085d6",
                        cancelButtonColor: "#d33",
                        confirmButtonText: "Yes, delete it!",
                      }).then((result) => {
                        if (result.isConfirmed) {
                          deleteContent(filter._id);
                        }
                      })
                    }
                  >
                    <DeleteForeverIcon sx={{ color: "red" }} />
                  </button>
                </div>
              </Grid>
            ))}
        </Grid>
      </motion.div>
    </div>
  );
}

export default ImageUpload;
