import React from "react";
import Banner from "../Components/Banner/BannerImg";
import CountNumber from "../Components/NumberCount/countNumber";
import { Route, Routes } from "react-router-dom";
import Navbar from "../Components/Navbar/Navbar";
import Showcase from "../Components/Showcase/Showcase";
import CusTestimonial from "../Components/Testimonial/CusTestimonial";
import GetFormData from "../Components/ContactUs/GetFormData";
import AboutUs from "../Components/AboutUs/AboutUs";
import CompanyLogo from "../Components/Logo/CompanyLogo";

function Home() {
  return (
    <>
      <Navbar />
      <div className="pt-5 mt-5 px-5">
        <Routes>
          <Route path="/dashboard" element={<Banner />} />
          <Route path="/" element={<Banner />} />
          <Route path="/showcase" element={<Showcase />} />
          <Route path="/count" element={<CountNumber />} />
          <Route path="/logo" element={<CompanyLogo />} />
          <Route path="/testimonial" element={<CusTestimonial />} />
          <Route path="/contactus" element={<GetFormData />} />
          <Route path="/aboutus" element={<AboutUs />} />
        </Routes>
      </div>
    </>
  );
}

export default Home;
