import React from "react";
import LoginForm from "./Pages/LoginForm";
import Home from "./Pages/Home";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import FgPassword from "./Pages/FgPassword";
import ResetPassword from "./Pages/ResetPassword";
import PrivateRoute from "./PrivateRoute";

function App() {
  return (
    // <>
    //   <div className="container">
    //     {/* <h1 className="text-danger my-5">Welcome KKK Admin Dashboard</h1> */}
    //     <LoginForm/>
    //     <ImageUpload/>
    //   </div>
    // </>
    <div style={{ background: "#222222", minHeight: "100vh" }}>
      <BrowserRouter>
        <Routes>
          <Route
            path="*"
            element={
              <PrivateRoute>
                <Home />
              </PrivateRoute>
            }
          />
          <Route path="/login" element={<LoginForm />} />
          <Route path="/forgotpassword" element={<FgPassword />} />
          <Route path="/reset/:id" element={<ResetPassword />} />
        </Routes>
      </BrowserRouter>
    </div>
  );
}

export default App;
