import React, { useEffect, useState } from "react";
import MailOutlineIcon from "@mui/icons-material/MailOutline";
import DateRangeIcon from "@mui/icons-material/DateRange";
import axios from "../../Api/axios";

function FormDetail({ id, close }) {
  const [formDetail, setFormDetail] = useState([]);
  //   console.log(id);

  async function fetchFormData() {
    try {
      const res = await axios.get(`api/v1/contact-us/${id}`);
      // console.log(res.data.submissions);
      setFormDetail(res.data.submissions);
    } catch (error) {
      console.log(error);
    }
  }

  const getDate = (data) => {
    let date = new Date(data);
    // console.log(date.getHours());
    /* Date format you have */
    let dateMDY = `${date.getDate()} / ${
      date.getMonth() + 1
    } / ${date.getFullYear()}, ${
      date.getHours() > 12 ? date.getHours() - 12 : date.getHours()
    }:${date.getMinutes() < 10 ? `0${date.getMinutes()}` : date.getMinutes()} ${
      date.getHours() > 12 ? "PM" : "AM"
    }`;
    /* Date converted to MM-DD-YYYY format */
    return dateMDY;
  };

  const closeDialog = () => {
    close();
  };

  //   console.log("formDetail", formDetail);

  useEffect(() => {
    fetchFormData();
  }, []);

  return (
    <div>
      {formDetail ? (
        <div className="bg-light form-detail">
          <h1 className="driodsan detail-title">{formDetail.company_name}</h1>
          <div className="mt-2">
            <MailOutlineIcon />
            <span className="ms-3">{formDetail.email}</span>
          </div>
          <div className="text-primary">
            <DateRangeIcon />
            <span className="ms-3">{getDate(formDetail.sentAt)}</span>
          </div>
          <p className="driodsan detail-message mt-4">
            {formDetail.about_project}
          </p>
          <button
            className="btn btn-outline-primary detailForm-close"
            onClick={() => closeDialog()}
          >
            close
          </button>
        </div>
      ) : (
        <p>Loading</p>
      )}
    </div>
  );
}

export default FormDetail;
