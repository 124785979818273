import { Box, Grid } from "@mui/material";
import axios from "../../Api/axios";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import React, { useEffect, useState } from "react";
import DeleteOutlineIcon from "@mui/icons-material/DeleteOutline";
import EditIcon from "@mui/icons-material/Edit";
import Swal from "sweetalert2";

function ShowcaseDetail(id) {
  const [content, setContent] = useState();
  const [title, setTitle] = useState();
  const [type, setType] = useState();
  const [desc, setDesc] = useState();
  const [category, setCategory] = useState();
  const [error, setError] = useState(false);
  const [edit, setEdit] = useState(false);
  const pjtypeArrays = [
    "Construction",
    "Steel Production",
    "Design",
    "Decoration",
  ];
  console.log(id);

  function closeDetail() {
    window.location.reload();
  }

  async function fetchContent() {
    try {
      const res = await axios.get(`api/v1/contents/${id.id}`);
      console.log(res.data.imageContent);
      setContent(res.data.imageContent);
      setTitle(res.data.imageContent.textContentRef.title);
      setDesc(res.data.imageContent.textContentRef.description);
      setType(res.data.imageContent.textContentRef.project_type);
      setCategory(res.data.imageContent.textContentRef.category);
    } catch (error) {
      console.log(error);
      setError("no contents");
    }
  }

  // console.log(title);

  async function deleteContent(id) {
    try {
      const res = await axios.delete(`api/v1/contents/${id}`);
      Swal.fire({
        title: "Deleted!",
        // text: "Do you want to continue",
        icon: "success",
        // confirmButtonText: "Cool",
      });
      window.location.reload();
    } catch (error) {
      console.log(error);
      Swal.fire({
        title: "Error!",
        text: "Do you want to continue",
        icon: "error",
        confirmButtonText: "Cool",
      });
    }
  }

  async function deleteImage(id) {
    try {
      await axios.delete(`api/v1/contents/images/${id}`);
      Swal.fire({
        title: "Deleted",
        text: "Do you want to continue",
        icon: "success",
      });
      fetchContent();
    } catch (error) {
      Swal.fire({
        title: "Error!",
        text: "Do you want to continue",
        icon: "error",
        confirmButtonText: "Cool",
      });
    }
  }

  function editdetail() {
    setEdit(true);
  }

  // console.log(edit);

  async function onKeyDownHandler(e) {
    const data = {
      title: title,
      description: desc,
      project_type: type,
      category: category,
    };
    console.log(data);
    try {
      await axios.put(`api/v1/contents/text/${id.id}`, data);
      setEdit(true);
      Swal.fire({
        title: "Updated!",
        text: "Do you want to continue",
        icon: "success",
        confirmButtonText: "Cool",
      });
    } catch (error) {
      Swal.fire({
        title: "Error!",
        text: error.response.data.message,
        icon: "error",
        confirmButtonText: "Cool",
      });
    }
  }

  useEffect(() => {
    fetchContent();
  }, [id, deleteImage]);

  return (
    <Box
      sx={{
        background: "#fff",
        px: 5,
        borderRadius: 5,
      }}
    >
      {error && <h1>{error}</h1>}
      {!error && (
        <div className="py-3">
          {content && (
            <Grid
              item
              xs={12}
              sx={{
                display: "flex",
                flexDirection: "column",
                justifyContent: "center",
                alignItems: "center",
                position: "relative",
              }}
            >
              <Box
                sx={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "space-between",
                  width: "100%",
                }}
              >
                <button
                  style={{
                    background: "#fff",
                    color: "#000",
                    padding: "5px 10px",
                    top: "20px",
                    left: "10px",
                    borderRadius: "5px",
                    fontSize: "24px",
                    fontWeight: "bold",
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    border: "none",
                  }}
                  onClick={() => closeDetail()}
                >
                  <ArrowBackIcon sx={{ marginRight: "20px" }} />
                  View Project Detail
                </button>
                <div>
                  {!edit && (
                    <div className="d-flex">
                      <button
                        style={{
                          background: "#fff",
                          display: "flex",
                          justifyContent: "center",
                          alignItems: "center",
                          color: "red",
                          borderRadius: "10px",
                          fontSize: "20px",
                          fontWeight: "bold",
                          padding: "10px 20px",
                          borderColor: "grey",
                        }}
                        onClick={() =>
                          deleteContent(content.textContentRef._id)
                        }
                      >
                        Delete <DeleteOutlineIcon />
                      </button>
                      <button
                        style={{
                          background: "#007FFF",
                          display: "flex",
                          justifyContent: "center",
                          alignItems: "center",
                          color: "white",
                          borderRadius: "10px",
                          fontSize: "20px",
                          fontWeight: "bold",
                          padding: "10px 20px",
                          border: "none",
                          marginLeft: "20px",
                        }}
                        onClick={editdetail}
                      >
                        Edit Details <EditIcon />
                      </button>
                    </div>
                  )}
                  {edit && (
                    <div className="d-flex">
                      <button
                        className="btn btn-primary mx-2"
                        style={{
                          fontSize: "20px",
                          fontWeight: "bold",
                        }}
                        onClick={onKeyDownHandler}
                      >
                        Update
                      </button>
                      <button
                        className="btn btn-danger text-white"
                        style={{
                          fontSize: "20px",
                          fontWeight: "bold",
                        }}
                        onClick={() => setEdit(false)}
                      >
                        Cancel
                      </button>
                    </div>
                  )}
                </div>
              </Box>

              <Box sx={{ width: "100%", mt: 2 }}>
                <div className="row">
                  <div className="col-4">
                    <div className="py-2 bg-dark rounded">
                      <div className="px-3">
                        <label
                          htmlFor="exampleFormControlInput1"
                          className="form-label text-light"
                        >
                          Project Title
                        </label>
                        <input
                          type="text"
                          readOnly={!edit}
                          className="form-control"
                          id="exampleFormControlInput1"
                          placeholder={content.textContentRef.title}
                          value={title}
                          onChange={(e) => setTitle(e.target.value)}
                        />
                      </div>
                    </div>
                  </div>

                  <div className="col-4">
                    <div className="py-2 bg-dark rounded">
                      <div className="px-3">
                        <label
                          htmlFor="exampleFormControlInput1"
                          className="form-label text-light"
                        >
                          Project Type
                        </label>
                        <input
                          type="text"
                          className="form-control"
                          id="exampleFormControlInput1"
                          placeholder={content.textContentRef.description}
                          value={type}
                          onChange={(e) => setType(e.target.value)}
                          readOnly={!edit}
                        />
                      </div>
                    </div>
                  </div>

                  <div className="col-4">
                    <div className="py-2 bg-dark rounded">
                      <div className="px-3">
                        <label
                          htmlFor="exampleFormControlInput1"
                          className="form-label text-light"
                        >
                          Project Category
                        </label>

                        <select
                          className="form-select bg-black text-light border-0"
                          aria-label="Default select example"
                          onChange={(e) => setCategory(e.target.value)}
                        >
                          <option defaultValue={category}>{category}</option>
                          {pjtypeArrays
                            .filter((pjtypeArray) => {
                              return pjtypeArray !== category;
                            })
                            .map((pjtypeArray) => {
                              return (
                                <option value={pjtypeArray} key={pjtypeArray}>
                                  {pjtypeArray}
                                </option>
                              );
                            })}
                        </select>
                      </div>
                    </div>
                  </div>

                  <div className="col-12 mt-3">
                    <div className="py-2 bg-dark rounded">
                      <div className="px-3">
                        <label
                          htmlFor="exampleFormControlInput1"
                          className="form-label text-light"
                        >
                          Project Type
                        </label>
                        <input
                          type="text"
                          className="form-control"
                          id="exampleFormControlInput1"
                          placeholder={content.textContentRef.description}
                          value={desc}
                          onChange={(e) => setDesc(e.target.value)}
                          readOnly={!edit}
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </Box>
            </Grid>
          )}
          <div className="bg-dark rounded p-3 mt-4" style={{ width: "100%" }}>
            <div className="mb-3">
              <p
                style={{
                  color: "#fff",
                  fontSize: "24px",
                  fontWeight: "bold",
                  marginBottom: "0px",
                }}
              >
                Project Images
              </p>

              {content && (
                <span className="text-white">
                  <span className="text-primary">{content.images.length}</span>
                  /32{" "}
                </span>
              )}
            </div>

            <div className="row">
              {content &&
                content.images.map((image) => (
                  <div className="col-lg-4 mt-4" key={image._id}>
                    <div className="showcase-imageContainer">
                      <img
                        src={`${process.env.REACT_APP_API_URL}/api/v1/contents/${image.filename}`}
                        alt={image.filename}
                        style={{
                          width: "inherit",
                          height: "inherit",
                        }}
                      />
                      <button
                        className="delete-btn"
                        onClick={() => deleteImage(image._id)}
                      >
                        <DeleteOutlineIcon sx={{ color: "red" }} />
                      </button>
                    </div>
                  </div>
                ))}
            </div>
          </div>
        </div>
      )}
    </Box>
  );
}

export default ShowcaseDetail;
