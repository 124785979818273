import axios from "axios";
const BASE_URL = process.env.REACT_APP_API_URL;
axios.defaults.baseURL = BASE_URL;
axios.defaults.headers.common["Accept"] = "application/json";

const setAuthToken = (token) => {
  if (token) {
    // If token is present, set it as default Authorization header for all requests
    axios.defaults.headers.common["Authorization"] = `Bearer ${token}`;
  }
};

axios.defaults.headers.common["Authorization"] =
  "Bearer " + localStorage.getItem("kk-token");

axios.defaults.headers.common["Content-Type"] = "application/json";

// axios.defaults.withCredentials = true;

export { setAuthToken };
export default axios;
