import { Box } from "@mui/system";
import axios from "../../Api/axios";
import { React, useState, useEffect } from "react";
import FilterListIcon from "@mui/icons-material/FilterList";
import DeleteForeverIcon from "@mui/icons-material/DeleteForever";
import Dialog from "@mui/material/Dialog";
import Checkbox from "@mui/material/Checkbox";

import "./contactus.css";
import Swal from "sweetalert2";
import FormDetail from "./FormDetail";

const label = { inputProps: { "aria-label": "Checkbox demo" } };

function GetFormData() {
  const [formData, setFormData] = useState([]);
  const [open, setOpen] = useState(false);
  const [id, setId] = useState("");
  const [selectedId, setSelectedId] = useState([]);
  const [showDelete, setShowDelete] = useState(false);
  const [showFilter, setShowFilter] = useState(false);
  const [oldest, setoldest] = useState(false);

  function selectId(id) {
    if (selectedId.includes(id)) {
      setSelectedId(selectedId.filter((item) => item !== id));
    } else {
      setSelectedId([...selectedId, id]);
    }
  }

  // console.log(selectedId);

  const handleClickOpen = (id) => {
    setOpen(true);
    setId(id);
  };

  const handleClose = () => {
    setOpen(false);
  };

  // Get All Form from Database
  async function fetchFormData() {
    try {
      const res = await axios.get("api/v1/contact-us");
      // console.log(res.data.submissions);
      setFormData(res.data.submissions);
    } catch (error) {
      console.log(error);
    }
  }

  const getDate = (data) => {
    let date = new Date(data);
    /* Date format you have */
    let dateMDY = `${date.getDate()} / ${
      date.getMonth() + 1
    } /${date.getFullYear()}`;
    return dateMDY;
  };

  // Delete Form
  async function deleteForm() {
    const data = {
      ids: selectedId,
    };
    try {
      await axios.post(`api/v1/contact-us/delete`, data);
      fetchFormData();
      setSelectedId([]);
      setShowDelete(false);
      Swal.fire({
        icon: "success",
        title: "Deleted!",
        text: "Your form has been deleted.",
      });
    } catch (error) {
      Swal.fire({
        icon: "error",
        title: "Oops...",
        text: error.response.data.message,
      });
    }
  }

  function reverseArray() {
    let newArray = formData.slice().reverse();
    setoldest(!oldest);
    setFormData(newArray);
  }

  console.log(formData);

  useEffect(() => {
    fetchFormData();
  }, []);

  return (
    <div>
      <div className="pb-3">
        <div className="d-flex justify-content-between align-items-center">
          <h4 className="contactTitle">Contact Mailbox</h4>
          <div className="d-flex">
            {showDelete ? (
              <button className="btn btn-danger mx-2" onClick={deleteForm}>
                <span className="droidsan mx-2">Confirm</span>
                <DeleteForeverIcon />
              </button>
            ) : (
              <button
                className="btn btn-outline-danger mx-2"
                onClick={() => setShowDelete(!showDelete)}
              >
                <span className="droidsan mx-2">Delete</span>
                <DeleteForeverIcon />
              </button>
            )}

            <div className="filter-containers">
              <button
                className="btn btn-primary"
                disabled={showDelete}
                onClick={() => setShowFilter(!showFilter)}
              >
                <span className="droidsan mx-2">Filter</span>
                <FilterListIcon />
              </button>
              {showFilter && (
                <div className="filter-box">
                  {/* <button className="btn btn-dark mb-2">
                    <span className="droidsan mx-2">Newest Messages</span>
                  </button> */}
                  <button className="btn btn-dark" onClick={reverseArray}>
                    {oldest ? (
                      <span className="droidsan mx-2">Newest Messages</span>
                    ) : (
                      <span className="droidsan mx-2">Oldest Messages</span>
                    )}
                  </button>
                </div>
              )}
            </div>
          </div>
        </div>

        <Box>
          {formData.length > 0 &&
            formData.map((data) => (
              <div className="messageBox" key={data._id}>
                {showDelete && (
                  <div>
                    <Checkbox
                      sx={{ color: "white" }}
                      {...label}
                      onClick={() => selectId(data._id)}
                    />
                  </div>
                )}
                <div
                  className="row"
                  style={{ width: "100%" }}
                  onClick={() => handleClickOpen(data._id)}
                >
                  <div className="col-2 d-flex justify-content-center align-items-center">
                    <p className="droidsan names text-center ms-5">
                      {data.company_name}
                    </p>
                  </div>
                  <div className="col-8">
                    <p className="droidsan messages">{data.about_project}</p>
                  </div>
                  <div className="col-2 d-flex justify-content-center">
                    <p className="droidsan dates">{getDate(data.sentAt)}</p>
                  </div>
                </div>
              </div>
            ))}
        </Box>

        <Dialog maxWidth="lg" open={open}>
          <FormDetail id={id} close={handleClose} />
        </Dialog>
      </div>
    </div>
  );
}

export default GetFormData;
