import axios from "../../Api/axios";
import React, { useContext, useState, useRef } from "react";
import AuthContext from "../../Contexts/AuthProvider";
import DeleteIcon from "@mui/icons-material/Delete";
import CloudUploadIcon from "@mui/icons-material/CloudUpload";
import Swal from "sweetalert2";

function LogoModal({ close }) {
  const [logos, setLogos] = useState("");
  const [links, setLinks] = useState("");
  const fileInputRef = useRef(null);

  function ImageHandler(e) {
    setLogos(e.target.files[0]);
  }

  function LinkHandler(e) {
    setLinks(e.target.value);
  }

  // To select the image
  function selectFile() {
    fileInputRef.current.click();
  }

  // To Delete the image
  function deleteImages() {
    setLogos(null);
    setLinks("");
  }

  function closeModal() {
    console.log("close");
    close();
  }

  async function ApiHandler() {
    const data = {
      logo: logos,
      url: links,
    };
    console.log(data);
    try {
      await axios
        .post("api/v1/logos/upload", data, {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        })
        .then((res) => {
          console.log(res);
          close();

          Swal.fire({
            text: "Logo Uploaded",
            icon: "success",
          });
        });
    } catch (error) {
      close();
      Swal.fire({
        title: "Error!",
        text: error.response.data.message,
        icon: "error",
        confirmButtonText: "Close",
      });
    }
  }

  return (
    <div className="px-3">
      <div className="d-flex justify-content-between">
        <h5 className="mb-3">Add Company Info</h5>
        <button className="delete text-black" onClick={closeModal}>
          Close
        </button>
      </div>

      <div className="mb-3">
        <div>
          <div className="py-2 px-3 bg-dark rounded d-flex justify-content-between">
            <div className="">
              <div>
                <label
                  htmlFor="exampleFormControlInput1"
                  className="form-label text-light"
                >
                  Choose Company Logo Image
                </label>
              </div>

              <input
                type="file"
                name="file"
                className="file my-1 rounded"
                ref={fileInputRef}
                onChange={ImageHandler}
              />
            </div>
            {logos && (
              <div className="">
                <img src={URL.createObjectURL(logos)} width={80} />
              </div>
            )}
          </div>
        </div>
      </div>

      <div className="mb-3">
        <div className="row py-2 mt-3">
          <div>
            <div className="py-2 bg-dark rounded">
              <div className="px-3">
                <label
                  htmlFor="exampleFormControlInput1"
                  className="form-label text-light"
                >
                  Add Company Related Link or Social Media Link
                </label>
                <input
                  type="text"
                  className="form-control mb-1"
                  id="exampleFormControlInput1"
                  placeholder="Enter Project Type"
                  value={links}
                  onChange={LinkHandler}
                />
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="pt-3 float-end">
        <button className="btn btn-outline-danger" onClick={deleteImages}>
          <DeleteIcon sx={{ color: "red" }} />
          <p style={{ marginBottom: "0", color: "red" }}>Discard</p>
        </button>
        <button className="btn btn-primary ms-2" onClick={ApiHandler}>
          <CloudUploadIcon />
          <p style={{ marginBottom: "0" }}>Upload</p>
        </button>
      </div>
    </div>
  );
}

export default LogoModal;
