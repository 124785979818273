import React from "react";
import "../Components/LoginForm.css";
import { useState, useContext } from "react";
import AuthContext from "../Contexts/AuthProvider";
import axios from "../Api/axios";
import { useNavigate } from "react-router-dom";
import Swal from "sweetalert2";
import { useParams } from "react-router-dom";

const ResetPassword = () => {
  const navigate = useNavigate();
  const id = useParams();

  const [resetPassword, setResetPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const { setAuth } = useContext(AuthContext);

  async function submitHandler(e) {
    e.preventDefault();
    const data = {
      password: resetPassword,
      confirmPassword: confirmPassword,
    };
    // console.log(data);
    try {
      const res = await axios.patch(
        "api/v1/users/reset-password/" + id.id,
        data
      );
      console.log(res);
      Swal.fire({
        title: "Success!",
        text: "Password has been reset",
        icon: "success",
      });
      navigate("/login");
    } catch (error) {
      Swal.fire({
        title: "Error!",
        text: error,
        icon: "error",
        confirmButtonText: "Close",
      });
    }
  }

  return (
    <>
      <section className="container">
        <div className="login">
          {/* <p ref={errRef} className={errMsg ? "errmsg" : "offscreen"} aria-live="assertive">{errMsg}</p> */}
          <h3 className="text-center">
            Reset <span style={{ color: "#007fff" }}>Password</span>
          </h3>
          <p className="text-center text-info mt-0">
            This process will expire in 10 minute
          </p>
          <form
            onSubmit={submitHandler}
            className="d-flex flex-column justify-center"
          >
            <div className="form-group mb-4 inputs">
              <label htmlFor="email" className="form-label">
                Set New Password
              </label>
              <input
                type="password"
                id="password"
                className="form-control"
                onChange={(e) => setResetPassword(e.target.value)}
                value={resetPassword}
                required
              />
              <div className="invalid-feedback">Please Enter Email</div>
            </div>

            <div className="form-group mb-2 inputs">
              <label htmlFor="password" className="form-label">
                Confirm New Password
              </label>
              <input
                type="password"
                id="confirmPassword"
                className="form-control"
                onChange={(e) => setConfirmPassword(e.target.value)}
                value={confirmPassword}
                required
              />
              <div className="invalid-feedback">Please Enter Your Password</div>
            </div>

            <button onClick={submitHandler} className="mt-3 loginBtns mx-auto">
              Reset Password
            </button>
          </form>
        </div>
      </section>
      {/* {errMsg ? (
        <div>
          <span>{errMsg}</span>
        </div>
      ) : null} */}
    </>
  );
};

export default ResetPassword;
